import React, { Fragment, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Context } from '../context';
import { PlayButton } from './PlayButton';
import { PreloaderGif } from './Preloader';

const languages = {
  'enlish': {
    title: 'En',
    titleLong: 'английский'
  },
  'de': {
    title: 'De',
    titleLong: 'немецкий'
  },
  'es': {
    title: 'Es',
    titleLong: 'испанский'
  },
  'chinese': {
    title: '汉',
    titleLong: 'китайский'
  },
}


export function Head({
  playShown = true,
  playSpacebar = true,
  titleShown = true,
  foldersShown = true,
  languageShown = true,
  saveShown = 'changed',
  videoToggleShown = true,
  context,
}) {

  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (value) => {
    context.handles.setToLang(value);
  }

  const finishEditing = () => {
    if (!context.state.lang || context.state.lang === "ru") {
      context.handles.metadataTranslate(context.state.folder, context.state.toLang);
    } else {
      context.handles.audioSynthesize(null, context.state.folder, context.state.mediaId, context.state.lang);
    }
  }

  const fixName = (name) => {
    if (name && typeof name === 'string') {
      name = name.replaceAll('_', ' ')
      return name
    } else {
      return 'Лекция без имени'
    }
  }

  const fixLang = (lang) => {
    if (!lang || !languages[lang]) return;
    return `Рус → ${languages[lang]['title']}`;
  }

  const handleVideo = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    if (context.state.video) {
      // Remove the 'video' parameter if it exists
      searchParams.delete('video');
      context.handles.setVideo()
    } else {
      // Add the 'video' parameter
      searchParams.set('video', '1');
      context.handles.setVideo(1)
    }

    // Construct the new path
    const newPath = `${location.pathname}?${searchParams.toString()}`;
    navigate(newPath);
  };


  return (
    <>
      <div className="x_head0">
        <div className="x_head_border" />
        <div className="x_head_l">
          <div className="x_head_logo">
            {playShown && <div className="x_player_control">
              <PlayButton
                playSpacebar={playSpacebar}
                enter={context.state.pop ? false : true} context={context} />
            </div>}

            {titleShown && <div className="x_head_logo_text ellipsis">{fixName(context.state['name'])}</div>}

            {foldersShown && <div className="x_head_chapters0">
              <FolderList
                // link={`/${context.state['mediaId']}?name=${context.state['name']}${context.state['lang'] ? `&lang=${context.state['lang']}` : ''}${context.state['video'] ? '&video=1' : ''}`}
                foldersTotal={context.state.foldersTotal}
                activeFolder={context.state.folder}
              />
            </div>}

            {languageShown && <div className="x_head_logo_lang">{fixLang(context.state['lang'])}</div>}
            {/* {languageShown && <div className="x_head_logo_lang">{context.state['lang']}</div>} */}

          </div>
          <div className='x_head_center'>

          </div>
        </div>


        <div className="x_head_r">
        {context.state.subtitleMode && <>
        <select
          style={{ width: '180px' }}
          value={context.state.toLang}
          onChange={(e) => {
            handleLanguageChange(e.target.value)
          }}

        >
          {Object.keys(languages).map(val => {
            return <option value={val}>{val}</option>
          })
          }
        </select>
        <button onClick={finishEditing}>Сохранить</button>
        </>}

          {saveShown && <> <div className="x_changes_count">
            <div className="x_flex-h">
              <div>Изменений:</div>
              <strong>{context.state[saveShown].length}</strong>
            </div>
          </div>

            <SaveButton context={context} /></>
          }


          {/* <div >
            <div className={`x_player_video_set_1 ${context.state.russian ? 'active' : ''}`}
              // onClick={() => context.handles.setVideo(context.state.video ? false : 'sm')}
              onClick={() => {
                context.handles.setRussian(r => !r)
              }}
            >
              Ру
            </div>
          </div> */}

          {videoToggleShown && <div >
            <div className={`x_player_video_set_1 ${context.state.video ? 'active' : ''}`}
              // onClick={() => context.handles.setVideo(context.state.video ? false : 'sm')}
              onClick={() => {
                handleVideo()
              }}
            >
              <div className="ico">
                videocam
              </div>
            </div>
          </div>}


        </div>



      </div>

    </>
  )
}



export function SaveButton(props) {
  const { context } = props;
  return (
    <>
      {!context.state.savingStatus && <div className="x_save x_save--saved"
        style={{ width: '8em' }}
      >
        <div>Сохранено</div>
        <div className="ico ico--20">
          <div>check</div>
        </div>
      </div>}

      {context.state.savingStatus && <div className="x_save "
        style={{ width: '8em' }}
      >
        {/* <div>Сохранение</div> */}
        <div style={{ position: 'relative', marginBottom: '-4px', marginTop: '-4px', marginLeft: '-4px', marginRight: '-4px' }}>
          <PreloaderGif size={44} />
        </div>
      </div>}
    </>
  )
}



export function SaveButton1(props) {
  const context = useContext(Context);
  return (
    <>
      {Boolean(context.state.changed.length) && <div className="x_save"
        onClick={context.handles.saveMoving}
      >
        <div>Сохранить</div>
      </div>}




      {!Boolean(context.state.changed.length) && !context.state.savingStatus && <div className="x_save x_save--saved">
        <div>Сохранено1</div>
        <div className="ico ico--20">
          <div>check</div>
        </div>
      </div>}

      {!Boolean(context.state.changed.length) && context.state.savingStatus && <div className="x_save x_save--saved">
        <div>Сохранеие</div>
        <div className="ico ico--20">
          <PreloaderGif />
        </div>
      </div>}



    </>
  )
}


export function FolderList({ foldersTotal, activeFolder, link = '' }) {
  const folders = [];
  const navigate = useNavigate();
  const location = useLocation();

  activeFolder = parseInt(activeFolder);

  const updateFolderParam = (i) => {
    // Create a new URLSearchParams object from the current location
    const searchParams = new URLSearchParams(location.search);

    // Update the folder parameter
    searchParams.set('folder', i);

    // Navigate to the new URL with updated search params
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const renderF = (i, arr) => {
    const className = i === activeFolder ? 'x_head_chapter active' : 'x_head_chapter';

    if (
      i === activeFolder ||
      (!(i > activeFolder + 2) && !(i < activeFolder - 2)) ||
      i === foldersTotal ||
      i === foldersTotal - 1 ||
      i === 1 ||
      i === 2
    ) {
      return (
        <span
          onClick={() => updateFolderParam(i)}
          key={`folder_${i}`}
          className={className}
          style={{ cursor: 'pointer' }}
        >
          {i}
        </span>
      );
    } else {
      return <Fragment key={`folder_dot_${i}`}>.</Fragment>;
    }
  };

  for (let i = 1; i <= foldersTotal; i++) {
    folders.push(renderF(i, folders));
  }

  return <>{folders}</>;
}