export const metadataToSubtitlesLanguages = {
  english: "en-US",
  en: "en-US",
  tr: "tr-TR",
  chinese: "ch-CH",
  ch: "ch-CH",
  es: "es-ES",
  lit: "lit-lit",
  de: "de-de",
  ru: "ru-RU",
}