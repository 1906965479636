import React, { useEffect, useState } from 'react'
import example_data from './audio/example_data.json';
import mp from './audio/torsunov.mp3';
import { metadataToSubtitlesLanguages } from './utils';



const API_URL = process.env.REACT_APP_API_URL;


const Context = React.createContext();

const debug = false;
const offline = false;


function SubtitlesProvider({ children }) {


  //TODO: example data
  const [mp3, setMp3] = useState(offline ? mp : null);
  const [mediaId, setMediaId] = useState(offline ? 'offline' : null);
  const [translationDataAll, setTranslationDataAll] = useState(offline ? [...example_data] : []);


  // const [mp3, setMp3] = useState(null);
  // const [mediaId, setMediaId] = useState();
  // const [translationDataAll, setTranslationDataAll] = useState([]);



  const [zoomFragment, setZoomFragment] = useState([0, 100])
  const [scrubberWidth, setScrubberWidth] = useState(10000)
  const [playStart, setPlayStart] = useState(10000)
  const [playOriginalStart, setPlayOriginalStart] = useState(10000)



  const [audioDuration, setAudioDuration] = useState(0)
  const [durationFull, setDurationFull] = useState(0)

  const [playing, setPlaying] = useState(false);
  const [playbackTime, setPlaybackTime] = useState(0);
  const [activeSegmentLength, setActiveSegmentLength] = useState(10);
  //The length of the active segment in PERCENTAGE of the total audio length






  const [playerReady, setplayerReady] = useState(false);

  const [name, setName] = useState('');
  const [lang, setLang] = useState('');
  const [toLang, setToLang] = useState('english');
  const [folder, setFolder] = useState(1);
  const [foldersTotal, setFoldersTotal] = useState(1);

  const [isDragging, setIsDragging] = useState(false);





  const [translationData, setTranslationData] = useState([]);

  const [playingFragment, setPlayingFragment] = useState();
  const [playingFragmentNext, setPlayingFragmentNext] = useState();

  const [volumeOriginal, setVolumeOriginal] = useState(40);
  const [volumeTranslated, setVolumeTranslated] = useState(60);

  const [zoomLevel, setZoomLevelState] = useState(() => {
    const savedZoomLevel = localStorage.getItem('zoomLevel');
    return savedZoomLevel ? JSON.parse(savedZoomLevel) : 80; // Default to 80 if not found
  });

  const [newData, setNewData] = useState([]);

  const [changedRecently, setChangedRecently] = useState([]);
  const [changed, setChanged] = useState([]);
  const [changedTranslation, setChangedTranslation] = useState([]);
  const [changedRus, setChangedRus] = useState([]);
  const [changedReels, setChangedReels] = useState([]);

  const [changeToSave, setChangeToSave] = useState();


  const [pop, setPopup] = useState()


  const [scrollTo, setScrollTo] = useState()

  const [video, setVideo] = useState()
  const [russian, setRussian] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')


  const [savingStatus, setSavingStatus] = useState(false)
  const [tracksSelected, setTracksSelected] = useState([]);


  const setZoomLevel = (newZoomLevel) => {
    setZoomLevelState(newZoomLevel);
    localStorage.setItem('zoomLevel', JSON.stringify(newZoomLevel));
  };

  const getPlayerFromLocal = () => {
    let d
    d = localStorage.getItem('playerType');
    // return d ? d : 'advanced';
    return d ? d : 'simple';
  };

  const [videoPlayerType, setVideoPlayerType2] = useState(getPlayerFromLocal())

  const setVideoPlayerType = (typ) => {
    localStorage.setItem('playerType', typ);
    setVideoPlayerType2(typ)
  }


  const [debugFullFunctionality, setDebugFullFunctionality] = useState(true)

  const [trimmed, setTrimmed] = useState([])
  const [lastFragmentTrimmed, setLastFragmentTrimmed] = useState(false)



  const [subtitleMode, setSubtitleMode] = useState(false)




  const setPop = (id) => {
    if (!id) {
      setChangedRecently([pop])
      setPopup()
      setTimeout(() => {
        setChangedRecently([])
      }, 2000);
    } else {
      setPopup(id)
      // scrollToElement(id)
      setChangedRecently([id])
      setTimeout(() => {
        setChangedRecently([])
      }, 2000);
    }
  }




  const saveToLocal = () => {
    localStorage.setItem(mediaId, JSON.stringify({
      'play': playingFragment,
      'changed': changed,
      'changedTranslation': changedTranslation,
      'changedRus': changedRus,
      'changedReels': changedReels,
    }));
  };


  const tryParse = (string) => {
    try {
      string = JSON.parse(string)
      return string
    } catch (error) {
      console.log('wrong format');
      return false
    }
  }



  const getFromLocal = (mediaId, what) => {
    if (!mediaId) return []
    let d
    d = localStorage.getItem(mediaId);
    d = Boolean(d) ? tryParse(d) : {
      'play': '',
      'changed': [],
      'changedTranslation': [],
      'changedRus': [],
      'changedReels': [],
    }
    if (what === 'changed') {
      d = (Boolean(d) && d['changed']) ? d['changed'] : [];
      return d;
    }
    if (what === 'changedTranslation') {
      d = (Boolean(d) && d['changedTranslation']) ? d['changedTranslation'] : [];
      return d;
    }
    if (what === 'changedRus') {
      d = (Boolean(d) && d['changedRus']) ? d['changedRus'] : [];
      return d;
    }
    if (what === 'changedReels') {
      d = (Boolean(d) && d['changedReels']) ? d['changedReels'] : [];
      return d;
    }
    if (what === 'play') {
      d = Boolean(d) ? d['play'] : '';
      return d;
    }
  };



  // useEffect(() => {
  //   newData.length && saveMoving()
  // }, [newData])



  const getNextStart = (id, currentData = translationData['translations'], dataAll = translationDataAll) => {
    let currentIndex = currentData.findIndex(one => one['id'] === id)
    if (currentIndex < currentData.length - 1) {
      return currentData[currentIndex + 1]['start']
    } else if (folder < dataAll.length) {
      const nextFolder = dataAll[folder + 1];
      if (nextFolder) {
        return nextFolder['translations'].length ? parseInt(nextFolder['prev_mp3_length_aggr']) + nextFolder['translations'][0]['shifted_start'] : null
      }
    }
    return null;
  }









  const prepareData = (allData, folder_num) => {
    const folder = parseInt(folder_num) - 1;
    const lastFragment = allData[allData.length - 1];
    let translationsArray = allData[folder]['translations'] || []
    if (!translationsArray.length) return []
    translationsArray = translationsArray
      .map((one, ind, arr) => prepareDataOne(one, folder_num, allData))
      .sort((a, b) => a.start - b.start)
    console.log('translationsArray 1 = ', translationsArray);
    // translationsArray = translationsArray.filter(one => one['folder_num'] === folder_num)
    // console.log('translationsArray 2 = ', translationsArray);
    let lastFragmentEnd = translationsArray[translationsArray.length - 1]['start'] + translationsArray[translationsArray.length - 1]['length'];
    if (lastFragmentEnd > audioDuration) {
      setDurationFull(lastFragmentEnd)
    }

    // setLang(translationsArray[0].lang)

    return translationsArray
  }



  const prepareDataOne = (one, folder_num, allData = []) => {
    const folder = parseInt(folder_num) - 1;
    let length;

    // if (!one['length']) {
    //   let calulatedLength = timeStringToMs(one['start_rus']) - timeStringToMs(one['end_rus']);
    //   one['length'] = calulatedLength;
    // };
    if (!one['length'] && one['start_rus_milli'] && one['end_rus_milli']) {
      let calulatedLength = one['end_rus_milli'] - one['start_rus_milli'];
      one['length'] = calulatedLength;
    };

    let translationsArray = allData[folder] ? allData[folder]['translations'] : [];
    let addLength = allData[folder] ? parseInt(allData[folder]['prev_mp3_length_aggr']) : 0;

    let nextTrackStart = getNextStart(one['id'], translationsArray, translationDataAll)
    if (nextTrackStart && nextTrackStart < one['start'] + one['length']) {
      length = nextTrackStart - one['start'];
      addTrimmed(`${one['folder_num']}_${one['id']}`)
      // setTrimmed(prevData => [...prevData, `${one['folder_num']}_${one['id']}`])
    }

    let start_rus_milli = one['start_rus_milli'] ? one['start_rus_milli'] : one['start']
    let end_rus_milli = one['end_rus_milli'] ? one['end_rus_milli'] : one['start'] + one['length']
    start_rus_milli = folder_num ? start_rus_milli - addLength : start_rus_milli
    start_rus_milli = start_rus_milli < 0 ? 0 : start_rus_milli
    end_rus_milli = folder_num ? end_rus_milli - addLength : end_rus_milli
    let start = folder_num ? one['start'] - addLength : one['start'];
    let trimmed = length ? one['length'] - length : null
    let trimmed_start = null;
    if (start < 0) {
      trimmed_start = start * -1;
      start = 0;
    }

    let newDataOne = {
      ...one,
      original_length: one['length'],
      length: length ? length : one['length'],
      trimmed: trimmed,
      trimmed_start: trimmed_start,
      original_start: one['start'],
      original_shifted_start: one['shifted_start'],
      start: start,
      start_rus_milli: start_rus_milli,
      end_rus_milli: end_rus_milli,
      original_id: one['id'],
      id: `${one['folder_num']}_${one['id']}`,
    }

    return newDataOne
  }





  const jsonPreview = (jsonString) => {
    const newWindow = window.open();
    newWindow.document.write(`<pre>${jsonString}</pre>`)
  };


  const saveMoving = async (newData, doing = 'move') => {
    console.log('doing = ', doing);
  
    const api = {
      "move": 'move_tts',
      "length": 'change_len_tts',
      "move_rus": 'metadata_edit_subtitle',
      "length_rus": 'metadata_edit_subtitle',
    };
  
    let addLength = parseInt(translationDataAll[parseInt(folder) - 1]['prev_mp3_length_aggr']) || 0;
  
    if (!changeToSave) return;
  
    saveToLocal();
    console.log('newData[0] = ', newData[0]);
  
    let jsonData = newData.find(one => one['id'] === changeToSave) || {};
    let newJsonData = {
      id: jsonData['original_id'],
      folder_num: jsonData['folder_num'],
      lang: jsonData['lang'],
    };
  
    if (doing === 'move') {
      newJsonData['shifted_start'] = jsonData['original_shifted_start'];
      newJsonData['new_shifted_start'] = jsonData['start'];
      newJsonData['start'] = jsonData['original_start'];
      newJsonData['new_start'] = addLength + jsonData['start'];
    }
  
    if (doing === 'move_rus') {
      newJsonData['start_rus_milli'] = addLength + jsonData['start_rus_milli'];
      newJsonData['end_rus_milli'] = addLength + jsonData['end_rus_milli'];
    }
  
    if (doing === 'length') {
      newJsonData['captions'] = jsonData['captions'];
      newJsonData['voice'] = jsonData['voice'];
      newJsonData['speed'] = jsonData['speed'];
      newJsonData['add_backround'] = jsonData['add_backround'];
      newJsonData['new_end_rus_milli'] = addLength + jsonData['end_rus_milli'];
    }
  
    if (doing === 'length_rus') {
      newJsonData['end_rus_milli'] = addLength + jsonData['end_rus_milli'];
    }
  
    const jsonString = JSON.stringify(newJsonData, null, 2);
  
    if (doing === 'move') {
      console.log('Sending this (moving ⏭) = ', jsonString);
    }
    if (doing === 'move_rus') {
      console.log('Sending this (moving RUS ⏭) = ', jsonString);
    }
    if (doing === 'length') {
      console.log('Sending this (length ⏭) = ', jsonString);
    }
    if (doing === 'length_rus') {
      console.log('Sending this (length RUS ⏭) = ', jsonString);
    }
  
    setSavingStatus(true);
  
    if (jsonString) {
      try {
        const data = await postData(`${API_URL}/${api[doing]}?media=${mediaId}`, jsonString);
        console.log('Server: ', data); // JSON data parsed by `response.json()` call
        setChangeToSave();
        setSavingStatus(false);
        return data;
      } catch (error) {
        console.error('Error in saveMoving:', error);
        setSavingStatus(false);
        throw error;
      }
    }
  };

  const saveCaptionsRus = async (track, captions) => {  
    let newJsonData = {
      id: track['original_id'],
      folder_num: track['folder_num'],
      lang: track['lang'],
      captions_rus: captions
    };
  
    const jsonString = JSON.stringify(newJsonData, null, 2);
    console.log('Sending this (captions_rus ⏭) = ', jsonString);
  
    setSavingStatus(true);
  
    if (jsonString) {
      try {
        const data = await postData(`${API_URL}/metadata_edit_subtitle?media=${mediaId}`, jsonString);
        console.log('Server: ', data);
        fetchTracks(mediaId, () => {});
        return data;
      } catch (error) {
        console.error('Error in saveCaptionsRus:', error);
        throw error;
      } finally {
        setSavingStatus(false);
      }
    }
  };

  const syntSpeechFromMeta = async (folderNum, lang, mediaId) => {  
    let newJsonData = {
      num: folderNum,
      lang: metadataToSubtitlesLanguages[lang] ?? "en-US",
    };
  
    const jsonString = JSON.stringify(newJsonData, null, 2);
    console.log('Sending this (metadata_to_subtitles ⏭) = ', jsonString);
  
    setSavingStatus(true);
  
    if (jsonString) {
      try {
        const data = await fetch(
          `${API_URL}/metadata_to_subtitles?media=${mediaId}`,
          {...querySettings, body: jsonString }
        );
        console.log('Server: ', data);
        const res = await fetch(`${API_URL}/synt_speech_from_meta?media=${mediaId}&num=${folderNum}&lang=${lang}`);
        console.log("res: ", res);
        fetchTracks(mediaId, () => {});
        return data;
      } catch (error) {
        console.error('Error in syntSpeechFromMeta:', error);
        throw error;
      } finally {
        setSavingStatus(false);
      }
    }
  };

  const metadataTranslate = async (folderNum, toLang) => {  
    let newJsonData = {
      nums: [folderNum],
      to_lang: toLang,
    };
  
    const jsonString = JSON.stringify(newJsonData, null, 2);
    console.log('Sending this (metadata translate ⏭) = ', jsonString);
  
    setSavingStatus(true);
  
    if (jsonString) {
      try {
        const data = await postData(`${API_URL}/metadata_translate?media=${mediaId}`, jsonString);
        console.log('Server: ', data);
        fetchTracks(mediaId, () => {});
        return data;
      } catch (error) {
        console.error('Error in metadataTranslate:', error);
        throw error;
      } finally {
        setSavingStatus(false);
      }
    }
  };

  const changeTracksSelected = (id) => {
    setTracksSelected((prev) => {
      return prev.includes(id) ? 
        prev.filter(item => item !== id)
        : [...prev, id]
    })
  }

  const mergeCaptions = async (track) => {
    if (tracksSelected.length !== 2) {
      return
    }

    let newJsonData = {
      id: tracksSelected,
      folder_num: track['folder_num'],
    };
  
    const jsonString = JSON.stringify(newJsonData, null, 2);
    console.log('Sending this (captions_rus ⏭) = ', jsonString);
  
    setSavingStatus(true);
  
    if (jsonString) {
      try {
        const data = await postData(`${API_URL}/metadata_merge_subtitles?media=${mediaId}`, jsonString);
        console.log('Server: ', data);
        fetchTracks(mediaId, () => {});
        setTracksSelected([]);
        return data;
      } catch (error) {
        console.error('Error in saveCaptionsRus:', error);
        throw error;
      } finally {
        setSavingStatus(false);
      }
    }
  }


  const getNextTrackId = (currentTrackId, tracks = translationData) => {
    const currentIndex = tracks.findIndex(track => track.id === currentTrackId);
    if (currentIndex === tracks.length - 1 || currentIndex === -1) {
      return null
    } else {
      return tracks[currentIndex + 1].id;
    }
  };
  const getPrevTrackId = (currentTrackId, tracks = translationData) => {
    const currentIndex = tracks.findIndex(track => track.id === currentTrackId);
    return currentIndex === 0 || currentIndex === -1 ? tracks[0]['id'] : tracks[currentIndex - 1].id;
  };


  const deleteFragment = (oneTrack) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this fragment?");
    if (isConfirmed) {
      let jsonData = {
        id: oneTrack['original_id'],
        // file: oneTrack['file'],
        folder_num: oneTrack['folder_num'],
        lang: oneTrack['lang']
        //lang: lang
      };
      const jsonString = JSON.stringify(jsonData, null, 2);
      // jsonPreview(jsonString)
      console.log('Sending this (deleting 🔴) = ', jsonString);
      setChanged(prev => prev.filter(one => one !== oneTrack['id']));
      addTrimmed(oneTrack['id'], 'remove');
      setSavingStatus(true);
      jsonString && postData(`${API_URL}/delete_tts?media=${mediaId}`, jsonString)
        .then(data => {
          setSavingStatus(false)
          console.log('Server: ', data); // JSON data parsed by `response.json()` call
          fetchTracks(mediaId, () => {
            setPop();
            scrollToElement(getPrevTrackId(oneTrack['id']));
          })
        });
    } else {
      // Do nothing if not confirmed
      console.log('Deletion cancelled.');
    }
  };

  const deleteSubtitle = (oneTrack) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this fragment?");
    if (isConfirmed) {
      let jsonData = {
        id: [oneTrack['original_id']],
        num: oneTrack['folder_num'],
      };
      const jsonString = JSON.stringify(jsonData, null, 2);
      console.log('Sending this (deleting 🔴) = ', jsonString);
      setChanged(prev => prev.filter(one => one !== oneTrack['id']));
      addTrimmed(oneTrack['id'], 'remove');
      setSavingStatus(true);
      jsonString && postData(`${API_URL}/metadata_delete_subtitle?media=${mediaId}`, jsonString)
        .then(data => {
          setSavingStatus(false)
          console.log('Server: ', data); // JSON data parsed by `response.json()` call
          fetchTracks(mediaId, () => {
            setPop();
            scrollToElement(getPrevTrackId(oneTrack['id']));
          })
        });
    } else {
      // Do nothing if not confirmed
      console.log('Deletion cancelled.');
    }
  };

  const querySettings = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  const postData = async (url = '', data = '') => {
    if (!data) {
      console.log('No data to send');
      return;
    }
    const response = await fetch(url, {...querySettings, body: data });
    return response.json();
  }


  const saveCaptionsBreak = (oneTrack, captions, voice, speed, background) => {
    const newTrack = {
      folder_num: oneTrack['folder_num'],
      id: oneTrack['original_id'],
      captions: captions,
      voice: voice,
      speed: speed,
      add_background: background,
      // file: oneTrack['file'],
      lang: oneTrack['lang'],
      // lang: lang
    };
    const jsonString = JSON.stringify(newTrack, null, 2);
    // jsonPreview(jsonString)
    console.log('Sending this (breaking ✂️) = ', jsonString);
    setWaitingCut((prev = []) => [...prev, newTrack.id]);
    setSavingStatus(true);
    jsonString && postData(`${API_URL}/split_tts_by_br?media=${mediaId}`, jsonString)
      .then(data => {
        console.log('Server: ', data); // JSON data parsed by `response.json()` call
        setSavingStatus(false);
        data = data.map(one => {
          return prepareDataOne(one)
        });

        markChangedTracks(data)
        fetchTracks(mediaId, () => {
          // scrollToElement(data[0]['id'])
          scrollToTime(data[0]['start'])
          setTimeout(() => {
            addNewChange(data[0].id, 'changed')
            setChangedRecently([data[0].id])
          }, 700);
          // setWaitingCut((prev = []) => prev.filter(o => o !== newTrack.id));
          openPopAfterCut(data)
        })
        // applyCaptionsBreak(data, oneTrack['original_id'])
      });
  };

  const saveCaptionsBreakSubtitles = (oneTrack, captions) => {
    const start = oneTrack.start_rus_milli;
    const end = oneTrack.end_rus_milli;
    const middle = start + Math.trunc((end - start) / 2);
    const newTrack = {
      num: oneTrack['folder_num'],
      id: oneTrack['original_id'],
      captions_rus: captions,
      new_start_rus_milli: [start, middle + 1],
      new_end_rus_milli: [middle, end],
    };
    const jsonString = JSON.stringify(newTrack, null, 2);
    // jsonPreview(jsonString)
    console.log('Sending this (breaking ✂️) = ', jsonString);
    setWaitingCut((prev = []) => [...prev, newTrack.id]);
    setSavingStatus(true);
    jsonString && postData(`${API_URL}/metadata_split_subtitle?media=${mediaId}`, jsonString)
      .then(data => {
        console.log('Server: ', data); // JSON data parsed by `response.json()` call
        setSavingStatus(false);
        data = data.map(one => {
          return prepareDataOne(one)
        });

        markChangedTracks(data)
        fetchTracks(mediaId, () => {
          // scrollToElement(data[0]['id'])
          scrollToTime(data[0]['start'])
          setTimeout(() => {
            addNewChange(data[0].id, 'changed')
            setChangedRecently([data[0].id])
          }, 700);
          // setWaitingCut((prev = []) => prev.filter(o => o !== newTrack.id));
          openPopAfterCut(data)
        })
        // applyCaptionsBreak(data, oneTrack['original_id'])
      });
  };



  const saveCaptionsTranslate = (oneTrack, captions) => {
    const newTrack = {
      folder_num: oneTrack['folder_num'],
      id: oneTrack['original_id'],
      captions: captions
    };
    const jsonString = JSON.stringify(newTrack, null, 2);
    // jsonPreview(jsonString)
    console.log('Sending this (breaking ✂️) = ', jsonString);
    setWaitingCut((prev = []) => [...prev, newTrack.id]);
    setSavingStatus(true);
    jsonString && postData(`${API_URL}/metadata_edit_subtitle?media=${mediaId}`, jsonString)
      .then(data => {
        console.log('Server: ', data); // JSON data parsed by `response.json()` call
        setSavingStatus(false);
        // markChangedTracks(data)

        addNewChange(oneTrack.id, 'changedTranslation')
        setChangedRecently([oneTrack.id])
        setTimeout(() => {
          setChangedRecently([])
        }, 700);

      });
  };

  const openPopAfterCut = (newData) => {
    // let newId = `${newData[0]['folder_num']}_${newData[0]['id']}`;
    let newId = newData[0]['id'];
    let attempts = 0;
    setPop(newId);
    // const maxAttempts = 10; // 10 attempts with a 500ms interval equals 5 seconds
    // const intervalId = setInterval(() => {
    //   if (translationData.filter(one => one['id'] === newId).length) {
    //     clearInterval(intervalId); // Clear the interval if the condition is met
    //     scrollToElement(newId)
    //   } else if (attempts >= maxAttempts) {
    //     clearInterval(intervalId); // Clear the interval if max attempts reached
    //     // Handle the case where the condition is not met within 5 seconds
    //     console.log("Condition not met within 5 seconds");
    //   }
    //   attempts++;
    // }, 1000); // Check every 500ms
  };

  const markChangedTracks = (arr) => {
    arr.forEach(one => addNewChange(`${one['folder_num']}_${one['id']}`))
    // arr.forEach(one => addNewChange(one['id']))
  }

  const [waitingCut, setWaitingCut] = useState([])




  const addTrimmed = (id, remove) => {
    setTrimmed((prevTrimmed) => {
      const newArray = [...prevTrimmed];
      const index = newArray.indexOf(id);
      if (index > -1) {
        newArray.splice(index, 1);
      }
      if (!remove) newArray.push(id);
      return newArray;
    });
  };


  const addNewChange = (id, what = 'changed') => {
    setChangeToSave(id)

    let setC;
    if (what === 'changed') setC = setChanged;
    if (what === 'changedTranslation') setC = setChangedTranslation;
    if (what === 'changedRus') setC = setChangedRus;
    if (what === 'changedReels') setC = setChangedReels;

    if (setC) setC((prevChanged = []) => {
      const newArray = [...prevChanged];
      const index = newArray.indexOf(id);
      if (index > -1) {
        newArray.splice(index, 1);
      }
      newArray.push(id);
      return newArray;
    });
  };

  useEffect(() => {
    // console.log('changed = ', changed);
    saveToLocal()
  }, [changed, changedTranslation, changedRus])

  useEffect(() => {
    checkTrimmed(newData)
  }, [folder])


  const fetchMp3 = (mediaId, folder) => {
    setMp3(null)
    fetch(`${API_URL}/download_rus?file_name=${mediaId}/files_to_send/${folder}.mp3`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.blob();
      })
      .then(blob => {
        console.log('MP3 = ', blob);
        const url = window.URL.createObjectURL(blob);
        setMp3(url);  // Store the object URL
      })
      .catch(error => {
        console.error("Error fetching MP3: ", error);
      });
  }

  useEffect(() => {
    const audio = new Audio(mp3);
    audio.onloadedmetadata = () => {
      setAudioDuration(audio.duration * 1000); // duration is in seconds, so multiply by 1000 to get milliseconds
      setDurationFull(audio.duration * 1000); // duration is in seconds, so multiply by 1000 to get milliseconds
    };
  }, [mp3]);

  useEffect(() => {
    if (playingFragment) {
      saveToLocal()
    }
  }, [playingFragment]);



  const fetchTracks = (mediaId, callback) => {
    setTranslationDataAll([])
    fetch(`${API_URL}/get_all_data_for_editing?media=${mediaId}${lang ? `&lang=${lang}` : ''}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          debug && console.log('data = ', JSON.stringify(data));
          setTranslationDataAll(data);
          // console.log('Translation = ', data);
          if (typeof callback === 'function') callback()
        }
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });
  }


  const fetchFolders = (mediaId) => {
    setFoldersTotal(1)
    fetch(`${API_URL}/cnt_fls?media=${mediaId}${lang ? `&lang=${lang}` : ''}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setFoldersTotal(data['numPages']);
      })
      .catch(error => {
        console.error("Error fetching folders: ", error);
      });
  }

  const fetchVideoUrl = (mediaId) => {
    setVideoUrl('')
    fetch(`${API_URL}/get_filename_from_media?media=${mediaId}${lang ? `&lang=${lang}` : ''}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(data => {
        console.log('VIDEO = ', data);
        setVideoUrl(data);
        setVideo('sm')
      })
      .catch(error => {
        console.error("Error fetching video: ", error);
      });
  }

  const playToggle = () => {
    if (playing) {
      setPlayingFragment()
    }
    setPlaying(!playing)
  }




  useEffect(() => {
    if (offline) return
    console.log('folder = ', folder);
    if (mediaId) {
      setAudioDuration(0)
      setDurationFull(0)
      setPlaybackTime(0)
      setPlaying(false)
      fetchFolders(mediaId)
      fetchMp3(mediaId, folder)
      fetchTracks(mediaId)
      setPlayingFragment()
      video && fetchVideoUrl(mediaId)
    }
    if (mediaId) {
      setChanged(getFromLocal(mediaId, 'changed'), 'changed')
      setChangedTranslation(getFromLocal(mediaId, 'changedTranslation'), 'changedTranslation')
      setChangedRus(getFromLocal(mediaId, 'changedRus'), 'changedRus')
    }
  }, [mediaId])




  useEffect(() => {
    if (translationDataAll.length && mediaId && folder) {
      fetchMp3(mediaId, folder)
    }
  }, [folder])

  useEffect(() => {
    if (translationDataAll.length && mediaId && folder) {
      // fetchMp3(mediaId, folder)
      console.log('setTranslationData = ');
      setTranslationData(prepareData(translationDataAll, folder))
      console.log('setTranslationData 🔴🌕🔴🌕🔴');
      video && fetchVideoUrl(mediaId)
    }
  }, [mediaId, translationDataAll, folder])

  useEffect(() => {
    if (video && !videoUrl && mediaId) { fetchVideoUrl(mediaId) }
  }, [video, mediaId])

  const getElementById = (id) => {
    if (!translationData.length) return {};
    let el;
    if (translationData.filter(one => one['id'] === id).length) {
      el = translationData.filter(one => one['id'] === id)[0]
    }
    return el ? el : {}
  }


  useEffect(() => {
    let id = getFromLocal(mediaId, 'play');
    if (id) {
      // scrollToElement(id)
    }
  }, [translationData])


  const scrollToElement = (id) => {
    if (!id) setScrollTo()
    if (translationData.length) {
      setScrollTo(getElementById(id)['start'] / (100 - zoomLevel) - 200)
      setTimeout(() => {
        setChangedRecently([id])
      }, 700);
    }
  }
  const scrollToTime = (time) => {
    // if (!id) setScrollTo()
    // if (translationData.length) {
    //   setScrollTo(time / (100 - zoomLevel) - 200)
    //   // setTimeout(() => {
    //   //   setChangedRecently([id])
    //   // }, 700);
    // }
  }

  const updateStart = async (id, newPosition, tracks, subtitles) => {
    let prevTracks = JSON.parse(JSON.stringify(tracks));
    let updatedTrack = prevTracks.find(one => one['id'] === id);
  
    if (!subtitles) {
      updatedTrack['start'] = newPosition;
    } else {
      updatedTrack['start_rus_milli'] = newPosition;
    }
  
    prevTracks = prevTracks.map(track =>
      track.id === id ? { ...updatedTrack } : track
    );
  
    let newTracks = checkTrimmed(prevTracks);
  
    await saveMoving(newTracks, subtitles ? 'move_rus' : 'move');
    setNewData(newTracks);
    setTranslationData(newTracks);
  };

  const updatePosition = async (id, newPosition, newLength, tracks) => {
    let prevTracks = JSON.parse(JSON.stringify(tracks));
    let updatedTrack = prevTracks.find(one => one['id'] === id);
  
    updatedTrack['start_rus_milli'] = newPosition;
    updatedTrack['end_rus_milli'] = newLength + updatedTrack['start_rus_milli'];
  
    prevTracks = prevTracks.map(track =>
      track.id === id ? { ...updatedTrack } : track
    );
  
    let newTracks = checkTrimmed(prevTracks);
  
    await saveMoving(newTracks, 'move_rus');
    setNewData(newTracks);
    setTranslationData(newTracks);
  };

  const updateLength = async (id, newLength, tracks, subtitles) => {
    let prevTracks = JSON.parse(JSON.stringify(tracks));
    let updatedTrack = prevTracks.find(one => one['id'] === id);
  
    if (!subtitles) {
      updatedTrack['length'] = newLength;
    } else {
      updatedTrack['end_rus_milli'] = newLength + updatedTrack['start_rus_milli'];
    }
  
    let newTracks = checkTrimmed(
      prevTracks.map(track =>
        track.id === id ? { ...updatedTrack } : track
      ),
      'keepLength'
    );

    await saveMoving(newTracks, subtitles ? 'length_rus' : 'length');
    setNewData(newTracks);
    setTranslationData(newTracks);
  };


  const checkTrimmed = (tracks, keepLength) => {
    setTrimmed([])
    return tracks.map((oneTrack, ind) => {
      let nextTrackStart = getNextStart(oneTrack['id'], tracks)
      let trimmed = nextTrackStart && nextTrackStart < oneTrack['start'] + oneTrack['original_length']
        ? oneTrack['start'] + oneTrack['original_length'] - nextTrackStart
        : null;

      oneTrack['trimmed'] = trimmed
      if (!keepLength) { oneTrack['length'] = trimmed ? nextTrackStart - oneTrack['start'] : oneTrack['original_length'] }
      if (trimmed) addTrimmed(oneTrack['id'])
      return oneTrack
    })

  }




  function timeStringToMs(timeString) {
    // Split the time string by colon and period
    const parts = timeString.split(':');
    const secondsParts = parts[2].split('.');

    // Extract hours, minutes, seconds, and milliseconds
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(secondsParts[0], 10);
    const milliseconds = parseInt(secondsParts[1], 10);

    // Calculate total milliseconds
    return (hours * 3600000) + (minutes * 60000) + (seconds * 1000) + milliseconds;
  }

  const audioSynthesize = async (trackId, folder, mediaId, lang) => {
    try {
      const body = {
        num: folder,
      }
      if (trackId) {
        body.id = [trackId];
      }
      if (lang) {
        body.lang = metadataToSubtitlesLanguages[lang] ?? "en-US";
      }
      const response = await fetch(`${API_URL}/metadata_synthesize?media=${mediaId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let data = await response.json();
      data = data.map(one => prepareDataOne(one));
      console.log('Audio synthesis response:', data);
      fetchTracks(mediaId, () => {

      })

      return data;
    } catch (error) {
      console.error('Error synthesizing audio:', error);
      throw error;
    }
  };






  return (<>
    <Context.Provider
      value={{
        debug: {
          debugFullFunctionality,
        },
        state: {
          isDragging,
          zoomFragment,
          audioDuration,
          playing,
          playbackTime,
          activeSegmentLength,
          mediaId,
          mp3,
          playerReady,
          translationData,
          name,
          lang,
          toLang,
          video,
          playingFragment,
          playingFragmentNext,
          volumeOriginal,
          volumeTranslated,
          zoomLevel,
          changedRecently,
          changed,
          changedTranslation,
          changedRus,
          newData,
          scrubberWidth,
          playStart,
          playOriginalStart,
          folder,
          foldersTotal,
          waitingCut,
          pop,
          scrollTo,
          videoUrl,
          trimmed,
          lastFragmentTrimmed,
          subtitleMode,
          videoPlayerType,
          savingStatus,
          russian,
          tracksSelected,
          durationFull
        },
        handles: {
          setIsDragging,
          getNextTrackId,
          getPrevTrackId,
          setZoomFragment,
          setAudioDuration,
          setPlaying,
          setPlaybackTime,
          setActiveSegmentLength,
          setMediaId,
          setMp3,
          setplayerReady,
          setTranslationData,
          setName,
          setLang,
          setToLang,
          setVideo,
          setPlayingFragment,
          setPlayingFragmentNext,
          playToggle,
          setVolumeOriginal,
          setVolumeTranslated,
          setZoomLevel,
          setNewData,
          addNewChange,
          setScrubberWidth,
          setPlayStart,
          setPlayOriginalStart,
          setFolder,
          saveMoving,
          saveCaptionsRus,
          saveCaptionsBreak,
          saveCaptionsBreakSubtitles,
          changeTracksSelected,
          mergeCaptions,
          setPop,
          setChangedRecently,
          setScrollTo,
          scrollToElement,
          setDebugFullFunctionality,
          getElementById,
          deleteFragment,
          deleteSubtitle,
          setSubtitleMode,
          getNextStart,
          updateStart,
          updatePosition,
          updateLength,
          setVideoPlayerType,
          setRussian,
          metadataTranslate,
          syntSpeechFromMeta,
          saveCaptionsTranslate,
          audioSynthesize,
        },
      }}
    >
      {children}
    </Context.Provider>
  </>
  )

}

const Consumer = Context.Consumer;

export { Context, SubtitlesProvider, Consumer };

